import React, { useEffect } from "react";

export default function Index() {
  useEffect(() => {
    document.body.classList.add("rm-scroll");

    return () => {
      document.body.classList.remove("rm-scroll");
    };
  });

  return (
    <>
      <div className="globalLoading">
        <div className="g-card">
          <div className="spinner-border text-light loading-icon"></div>
        </div>
      </div>
    </>
  );
}
