const EMPLOYEE_INITIAL_FETCHING_VALUES = {
  isGetEmployeeFetching: false,
  isGetEmployeeByIdFetching: false,
  isGetEmployeeRegLinkFetching: false,
  isGetAllEmployeeForCompanyFetching: false,
  isDisableEmployeeFetching: false,
  isGetEmpDetailsFetching: false,
};

export const EMPLOYEE_INITIAL_VALUES = {
  employees: [],
  companyEmployees: [],
  empData: [],
  employeeByIdDetails: null,
  error: false,
  employeeFetchingState: {
    ...EMPLOYEE_INITIAL_FETCHING_VALUES,
  },
};
