const CompanyReducer = (state, action) => {
  switch (action.type) {
    case "SEND_COMPANY_NAME_START":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isSendCompanyNameFetching: true,
        },
        error: false,
      };
    case "SEND_COMPANY_NAME_SUCCESS":
      return {
        ...state,
        companies: action.payload.data,
        companyFetchingState: {
          ...state.companyFetchingState,
          isSendCompanyNameFetching: false,
        },
        error: false,
      };
    case "SEND_COMPANY_NAME_FAILURE":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isSendCompanyNameFetching: false,
        },
        error: true,
      };
    case "GET_COMPANY_BY_ID_START":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isGetCompanyByIdFetching: true,
        },
        error: false,
      };
    case "GET_COMPANY_BY_ID_SUCCESS":
      return {
        ...state,
        companyByIdDetails: action.payload,
        companyFetchingState: {
          ...state.companyFetchingState,
          isGetCompanyByIdFetching: false,
        },
        error: false,
      };
    case "GET_COMPANY_BY_ID_FAILURE":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isGetCompanyByIdFetching: false,
        },
        error: true,
      };
    case "DISABLE_COMPANY_START":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isDisableCompanyFetching: true,
        },
        error: false,
      };
    case "DISABLE_COMPANY_SUCCESS":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isDisableCompanyFetching: false,
        },
        error: false,
      };
    case "DISABLE_COMPANY_FAILURE":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isDisableCompanyFetching: false,
        },
        error: true,
      };
    case "CREATE_COMPANY_START":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isCreateCompanyFetching: true,
        },
        error: false,
      };
    case "CREATE_COMPANY_SUCCESS":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isCreateCompanyFetching: false,
        },
        error: false,
      };
    case "CREATE_COMPANY_FAILURE":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isCreateCompanyFetching: false,
        },
        error: true,
      };
    case "UPDATE_COMPANY_START":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isUpdateCompanyFetching: true,
        },
        error: false,
      };
    case "UPDATE_COMPANY_SUCCESS":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isUpdateCompanyFetching: false,
        },
        error: false,
      };
    case "UPDATE_COMPANY_FAILURE":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isUpdateCompanyFetching: false,
        },
        error: true,
      };
    default:
      return { ...state };
  }
};

export default CompanyReducer;
