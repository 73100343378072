const ProgrammeReducer = (state, action) => {
  switch (action.type) {
    case "GET_DETAILS_FOR_PROGRAMME_INSIGHTS_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetDetailsForProgrammeInsightsFetching: true,
        },
        error: false,
      };
    case "GET_DETAILS_FOR_PROGRAMME_INSIGHTS_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetDetailsForProgrammeInsightsFetching: false,
        },
        error: false,
      };
    case "GET_DETAILS_FOR_PROGRAMME_INSIGHTS_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetDetailsForProgrammeInsightsFetching: false,
        },
        error: true,
      };
    case "UPDATE_PRACTITIONER_PROGRAMME_IS_COMPLETE_STATUS_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isUpdatePractitionerProgrammeIsCompleteFetching: true,
        },
        error: false,
      };
    case "UPDATE_PRACTITIONER_PROGRAMME_IS_COMPLETE_STATUS_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isUpdatePractitionerProgrammeIsCompleteFetching: false,
        },
        error: false,
      };
    case "UPDATE_PRACTITIONER_PROGRAMME_IS_COMPLETE_STATUS_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isUpdatePractitionerProgrammeIsCompleteFetching: false,
        },
        error: true,
      };
    case "GET_ALL_UP_COMING_PROGRAMMES_BOOKINGS_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetAllUpComingProgrammesBookingFetching: true,
        },
        error: false,
      };
    case "GET_ALL_UP_COMING_PROGRAMMES_BOOKINGS_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetAllUpComingProgrammesBookingFetching: false,
        },
        error: false,
      };
    case "GET_ALL_UP_COMING_PROGRAMMES_BOOKINGS_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetAllUpComingProgrammesBookingFetching: false,
        },
        error: true,
      };
    case "GET_ALL_PAST_PROGRAMMES_BOOKINGS_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetAllPastProgrammesBookingFetching: true,
        },
        error: false,
      };
    case "GET_ALL_PAST_PROGRAMMES_BOOKINGS_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetAllPastProgrammesBookingFetching: false,
        },
        error: false,
      };
    case "GET_ALL_PAST_PROGRAMMES_BOOKINGS_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetAllPastProgrammesBookingFetching: false,
        },
        error: true,
      };
    case "GET_ALL_PROGRAMMES_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetAllProgrammeFetching: true,
        },
        error: false,
      };
    case "GET_ALL_PROGRAMMES_SUCCESS":
      return {
        ...state,
        getAllProgrammesList: action.payload,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetAllProgrammeFetching: false,
        },
        error: false,
      };
    case "GET_ALL_PROGRAMMES_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetAllProgrammeFetching: false,
        },
        error: true,
      };
    case "GET_PROGRAMME_DETAILS_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isProgrammeDetailsFetching: true,
        },
        error: false,
      };
    case "GET_PROGRAMME_DETAILS_SUCCESS":
      return {
        ...state,
        programmeList: action.payload,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isProgrammeDetailsFetching: false,
        },
        error: false,
      };
    case "GET_PROGRAMME_DETAILS_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isProgrammeDetailsFetching: false,
        },
        error: true,
      };
    case "GET_PROGRAMME_LIST_FOR_PROGRAMME_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isProgrammeListForProgrammeFetching: true,
        },
        error: false,
      };
    case "GET_PROGRAMME_LIST_FOR_PROGRAMME_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isProgrammeListForProgrammeFetching: false,
        },
        error: false,
      };
    case "GET_PROGRAMME_LIST_FOR_PROGRAMME_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isProgrammeListForProgrammeFetching: false,
        },
        error: true,
      };
    case "GET_SHAREDDATA_FOR_PROGRAMME_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetSharedDataForProgrammeFetching: true,
        },
        error: false,
      };
    case "GET_SHAREDDATA_FOR_PROGRAMME_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetSharedDataForProgrammeFetching: false,
        },
        error: false,
      };
    case "GET_SHAREDDATA_FOR_PROGRAMME_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetSharedDataForProgrammeFetching: false,
        },
        error: true,
      };
    case "UPDATE_PROGRAMME_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isUpdateProgrammeFetching: true,
        },
        error: false,
      };
    case "UPDATE_PROGRAMME_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isUpdateProgrammeFetching: false,
        },
        error: false,
      };
    case "UPDATE_PROGRAMME_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isUpdateProgrammeFetching: false,
        },
        error: true,
      };
    case "CREATE_PROGRAMME_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isCreateProgrammeFetching: true,
        },
        error: false,
      };
    case "CREATE_PROGRAMME_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isCreateProgrammeFetching: false,
        },
        error: false,
      };
    case "CREATE_PROGRAMME_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isCreateProgrammeFetching: false,
        },
        error: true,
      };
    case "CHANGE_PROGRAMME_RECORD_STATUS_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isChangeProgrammeRecordStatusFetching: true,
        },
        error: false,
      };
    case "CHANGE_PROGRAMME_RECORD_STATUS_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isChangeProgrammeRecordStatusFetching: false,
        },
        error: false,
      };
    case "CHANGE_PROGRAMME_RECORD_STATUS_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isChangeProgrammeRecordStatusFetching: false,
        },
        error: true,
      };
    default:
      return { ...state };
  }
};

export default ProgrammeReducer;
