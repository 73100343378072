import { createContext, useReducer, useEffect, useState } from "react";
import { combineReducers } from "./CombineReducers";
import AuthReducer from "./authContext/AuthReducer";
import CompanyReducer from "./companyContext/CompanyReducer";
import EmployeeReducer from "./employeeContext/EmployeeReducer";
import SharedDataReducer from "./sharedDataContext/SharedDataReducer";
import IdentityServiceReducer from "./identityServiceContext/IdentityServiceReducer";
import PractitionerReducer from "./practitionerContext/PractitionerReducer";
import ProgrammeReducer from "./programmeContext/ProgrammeReducer";
import { PRACTITIONER_INITIAL_VALUES } from "./practitionerContext/practitionerContextInitialValues";
import { AUTH_INITIAL_VALUES } from "./authContext/AuthContextInitialValues";
import { COMPANY_INITIAL_VALUES } from "./companyContext/CompanyContextInitialValues";
import { EMPLOYEE_INITIAL_VALUES } from "./employeeContext/EmployeeContextInitialValues";
import { IDENTITY_INITIAL_VALUES } from "./identityServiceContext/IdentityContextInitialValues";
import { SHARED_DATA_INITIAL_VALUES } from "./sharedDataContext/SharedDataContextInitialValues";
import { PROGRAMME_INITIAL_VALUES } from "./programmeContext/ProgrammeContextInitialValues";

const INITIAL_STATE = {
  AuthReducer: {
    ...AUTH_INITIAL_VALUES,
  },
  CompanyReducer: {
    ...COMPANY_INITIAL_VALUES,
  },
  EmployeeReducer: {
    ...EMPLOYEE_INITIAL_VALUES,
  },
  IdentityServiceReducer: {
    ...IDENTITY_INITIAL_VALUES,
  },
  PractitionerReducer: {
    ...PRACTITIONER_INITIAL_VALUES,
  },
  SharedDataReducer: {
    ...SHARED_DATA_INITIAL_VALUES,
  },
  ProgrammeReducer: {
    ...PROGRAMME_INITIAL_VALUES,
  },
};

const appReducers = combineReducers({
  AuthReducer,
  CompanyReducer,
  EmployeeReducer,
  SharedDataReducer,
  IdentityServiceReducer,
  ProgrammeReducer,
  PractitionerReducer,
});

export const MainContext = createContext(INITIAL_STATE);

export const MainContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducers, INITIAL_STATE);
  const [clickedError, setClickedError] = useState({
    status: false,
    error: [],
    error1: "",
  });

  const loderStatus = {
    ...state.PractitionerReducer.practitionerFetchingState,
    ...state.AuthReducer.authFetchingState,
    ...state.CompanyReducer.companyFetchingState,
    ...state.EmployeeReducer.employeeFetchingState,
    ...state.IdentityServiceReducer.identityFetchingState,
    ...state.SharedDataReducer.sharedDataFetchingState,
    ...state.ProgrammeReducer.programmeFetchingState,
  };
  const globalLoaderState = Object.values(loderStatus).some((val) => val);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.AuthReducer.user));
    localStorage.setItem(
      "userDetails",
      JSON.stringify(state.AuthReducer.userDetails)
    );
  }, [state.AuthReducer.user, state.AuthReducer.userDetails]);

  return (
    <MainContext.Provider
      value={{
        user: state.AuthReducer.user,
        userData: state.AuthReducer.userData,
        companies: state.CompanyReducer.companies,
        userDetails: state.AuthReducer.userDetails,
        companyByIdDetails: state.CompanyReducer.companyByIdDetails,
        employees: state.EmployeeReducer.employees,
        employeeByIdDetails: state.EmployeeReducer.employeeByIdDetails,
        empData: state.EmployeeReducer.empData,
        companyEmployees: state.EmployeeReducer.companyEmployees,
        practitioners: state.PractitionerReducer.practitioners,
        practitionerDetails: state.PractitionerReducer.practitionerDetails,
        bookingList: state.PractitionerReducer.bookingList,
        getAllProgrammesList: state.ProgrammeReducer.getAllProgrammesList,
        programmeList: state.ProgrammeReducer.programmeList,
        practitionerDocuments: state.PractitionerReducer.practitionerDocuments,
        getTimeZonesList: state.PractitionerReducer.getTimeZonesList,
        error:
          state.AuthReducer.error ||
          state.PractitionerReducer.error ||
          state.CompanyReducer.error ||
          state.EmployeeReducer.error ||
          state.IdentityServiceReducer.error ||
          state.SharedDataReducer.error ||
          state.ProgrammeReducer.error,
        isLoading: globalLoaderState,
        dispatch,
        clickedError,
        setClickedError,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
