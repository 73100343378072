const PROGRAMME_INITIAL_FETCHING_VALUES = {
  isGetDetailsForProgrammeInsightsFetching: false,
  isUpdatePractitionerProgrammeIsCompleteFetching: false,
  isGetAllUpComingProgrammesBookingFetching: false,
  isGetAllPastProgrammesBookingFetching: false,
  isGetAllProgrammeFetching: false,
  isProgrammeDetailsFetching: false,
  isProgrammeListForProgrammeFetching: false,
  isGetSharedDataForProgrammeFetching: false,
  isUpdateProgrammeFetching: false,
  isCreateProgrammeFetching: false,
  isChangeProgrammeRecordStatusFetching: false,
};

export const PROGRAMME_INITIAL_VALUES = {
  error: false,
  getAllProgrammesList: [],
  programmeList: {},
  programmeFetchingState: {
    ...PROGRAMME_INITIAL_FETCHING_VALUES,
  },
};
