const EmployeeReducer = (state, action) => {
  switch (action.type) {
    case "GET_EMPLOYEE_START":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployeeFetching: true,
        },
        error: false,
      };
    case "GET_EMPLOYEE_SUCCESS":
      return {
        ...state,
        employees: action.payload,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployeeFetching: false,
        },
        error: false,
      };
    case "GET_EMPLOYEE_FAILURE":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployeeFetching: false,
        },
        error: true,
      };
    case "GET_EMPLOYEE_BY_ID_START":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployeeByIdFetching: true,
        },
        error: false,
      };
    case "GET_EMPLOYEE_BY_ID_SUCCESS":
      return {
        ...state,
        employeeByIdDetails: action.payload,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployeeByIdFetching: false,
        },
        error: false,
      };
    case "GET_EMPLOYEE_BY_ID_FAILURE":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployeeByIdFetching: false,
        },
        error: true,
      };
    case "GET_EMPLOYEE_REG_LINK_START":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployeeRegLinkFetching: true,
        },
        error: false,
      };
    case "GET_EMPLOYEE_REG_LINK_SUCCESS":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployeeRegLinkFetching: false,
        },
        error: false,
      };
    case "GET_EMPLOYEE_REG_LINK_FAILURE":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployeeRegLinkFetching: false,
        },
        error: true,
      };
    case "GET_ALL_EMP_FOR_COMPANY_START":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetAllEmployeeForCompanyFetching: true,
        },
        error: false,
      };
    case "GET_ALL_EMP_FOR_COMPANY_SUCCESS":
      return {
        ...state,
        companyEmployees: action.payload,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetAllEmployeeForCompanyFetching: false,
        },
        error: false,
      };
    case "GET_ALL_EMP_FOR_COMPANY_FAILURE":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetAllEmployeeForCompanyFetching: false,
        },
        error: true,
      };
    case "DISABLE_EMPLOYEE_START":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isDisableEmployeeFetching: true,
        },
        error: false,
      };
    case "DISABLE_EMPLOYEE_SUCCESS":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isDisableEmployeeFetching: false,
        },
        error: false,
      };
    case "DISABLE_EMPLOYEE_FAILURE":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isDisableEmployeeFetching: false,
        },
        error: true,
      };
    case "GET_EMP_DETAILS_START":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmpDetailsFetching: true,
        },
        error: false,
      };
    case "GET_EMP_DETAILS_SUCCESS":
      return {
        ...state,
        empData: action.payload,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmpDetailsFetching: false,
        },
        error: false,
      };
    case "GET_EMP_DETAILS_FAILURE":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmpDetailsFetching: false,
        },
        error: true,
      };
    default: {
      return { ...state };
    }
  }
};

export default EmployeeReducer;
