const PractitionerReducer = (state, action) => {
  switch (action.type) {
    case "GET_ALL_PRACTITIONER_START":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetAllPractitionerFetching: true,
        },
        error: false,
      };
    case "GET_ALL_PRACTITIONER_SUCCESS":
      return {
        ...state,
        practitioners: action.payload,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetAllPractitionerFetching: false,
        },
        error: false,
      };
    case "GET_ALL_PRACTITIONER_FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetAllPractitionerFetching: false,
        },
        error: true,
      };
    case "GET_PRACTITIONER_DETAILS_START":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerDetailsFetching: true,
        },
        error: false,
      };
    case "GET_PRACTITIONER_DETAILS_SUCCESS":
      return {
        ...state,
        practitionerDetails: action.payload.data,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerDetailsFetching: false,
        },
        error: false,
      };
    case "GET_PRACTITIONER_DETAILS_FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerDetailsFetching: false,
        },
        error: true,
      };
    case "GET_PRACTITIONER_PROGRAMME_LIST_START":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerProgrammeListFetching: true,
        },
        error: false,
      };
    case "GET_PRACTITIONER_PROGRAMME_LIST_SUCCESS":
      return {
        ...state,
        // programme: action.payload,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerProgrammeListFetching: false,
        },
        error: false,
      };
    case "GET_PRACTITIONER_PROGRAMME_LIST__FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerProgrammeListFetching: false,
        },
        error: true,
      };
    case "GET_PRACTITIONER_EXTERNAL_LOGIN_DETAILS_START":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerExternalLoginDetailsFetching: true,
        },
        error: false,
      };
    case "GET_PRACTITIONER_EXTERNAL_LOGIN_DETAILS_SUCCESS":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerExternalLoginDetailsFetching: false,
        },
        error: false,
      };
    case "GET_PRACTITIONER_EXTERNAL_LOGIN_DETAILS_FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerExternalLoginDetailsFetching: false,
        },
        error: true,
      };
    case "GET_PRACTITIONER_PROGRAMME_BOOKINS_START":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerProgrammeBookingFetching: true,
        },
        error: false,
      };
    case "GET_PRACTITIONER_PROGRAMME_BOOKINS_SUCCESS":
      return {
        ...state,
        bookingList: action.payload.data,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerProgrammeBookingFetching: false,
        },
        error: false,
      };
    case "GGET_PRACTITIONER_PROGRAMME_BOOKINS_FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerProgrammeBookingFetching: false,
        },
        error: true,
      };
    case "GET_PRACTITIONER_LIST_FOR_PROGRAMME_START":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerListForProgrammeFetching: true,
        },
        error: false,
      };
    case "GET_PRACTITIONER_LIST_FOR_PROGRAMME_SUCCESS":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerListForProgrammeFetching: false,
        },
        error: false,
      };
    case "GET_PRACTITIONER_LIST_FOR_PROGRAMME_FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerListForProgrammeFetching: false,
        },
        error: true,
      };
    case "CREATE_PRACTITIONER_START":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isCreatePractitionerFetching: true,
        },
        error: false,
      };
    case "CREATE_PRACTITIONER_SUCCESS":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isCreatePractitionerFetching: false,
        },
        error: false,
      };
    case "CREATE_PRACTITIONER_FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isCreatePractitionerFetching: false,
        },
        error: true,
      };
    case "UPDATE_PRACTITIONER_START":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isUpdatePractitionerFetching: true,
        },
        error: false,
      };
    case "UPDATE_PRACTITIONER_SUCCESS":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isUpdatePractitionerFetching: false,
        },
        error: false,
      };
    case "UPDATE_PRACTITIONER_FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isUpdatePractitionerFetching: false,
        },
        error: true,
      };
    case "GET_PRACTITIONER_DOCUMENTS_START":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerDocumentsFetching: true,
        },
        error: false,
      };
    case "GET_PRACTITIONER_DOCUMENTS_SUCCESS":
      return {
        ...state,
        practitionerDocuments: action.payload,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerDocumentsFetching: false,
        },
        error: false,
      };
    case "GET_PRACTITIONER_DOCUMENTS_FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerDocumentsFetching: false,
        },
        error: true,
      };
    case "DELETE_PRACTITIONER_DOCUMENT_STRAT":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isDeletePractitionerDocumentFetching: true,
        },
        error: false,
      };
    case "DELETE_PRACTITIONER_DOCUMENT_SUCCESS":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isDeletePractitionerDocumentFetching: false,
        },
        error: false,
      };
    case "DELETE_PRACTITIONER_DOCUMENT_FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isDeletePractitionerDocumentFetching: false,
        },
        error: true,
      };
    case "DISABLE_PRACTITIONER_STRAT":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isDisablePractitionerFetching: true,
        },
        error: false,
      };
    case "DISABLE_PRACTITIONER_SUCCESS":
      return {
        ...state,
        practitionerDocuments: action.payload,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isDisablePractitionerFetching: false,
        },
        error: false,
      };
    case "DISABLE_PRACTITIONER_FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isDisablePractitionerFetching: false,
        },
        error: true,
      };
    case "GET_TIME_ZONES_STRAT":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetTimeZonesFetching: true,
        },
        error: false,
      };
    case "GET_TIME_ZONES_SUCCESS":
      return {
        ...state,
        getTimeZonesList: action.payload,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetTimeZonesFetching: false,
        },
        error: false,
      };
    case "GET_TIME_ZONES_FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetTimeZonesFetching: false,
        },
        error: true,
      };
    default:
      return { ...state };
  }
};

export default PractitionerReducer;
