const IDENTITY_INITIAL_FETCHING_VALUES = {
  isRestPasswordFetching: false,
  isCommonRestPasswordFetching: false,
  isGetUserProfilePictureFetching: false,
  isAdminRestPasswordFetching: false,
  isAddNewEmployerFetching: false,
  isgenerateEmployerRegistrationCompleteLinkFetching: false,
};

export const IDENTITY_INITIAL_VALUES = {
  error: false,
  identityFetchingState: {
    ...IDENTITY_INITIAL_FETCHING_VALUES,
  },
};
