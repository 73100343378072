const PRACTIIONER_INITIAL_FETCHING_VALUES = {
  isGetAllPractitionerFetching: false,
  isGetPractitionerDetailsFetching: false,
  isGetPractitionerProgrammeListFetching: false,
  isGetPractitionerExternalLoginDetailsFetching: false,
  isGetPractitionerProgrammeBookingFetching: false,
  isGetPractitionerListForProgrammeFetching: false,
  isCreatePractitionerFetching: false,
  isUpdatePractitionerFetching: false,
  isGetPractitionerDocumentsFetching: false,
  isDeletePractitionerDocumentFetching: false,
  isDisablePractitionerFetching: false,
  isGetTimeZonesFetching: false,
};

export const PRACTITIONER_INITIAL_VALUES = {
  practitioners: {},
  practitionerDetails: {},
  practitionerDocuments: [],
  bookingList: [],
  getTimeZonesList: null,
  error: false,
  practitionerFetchingState: {
    ...PRACTIIONER_INITIAL_FETCHING_VALUES,
  },
};
