const IdentityServiceReducer = (state, action) => {
  switch (action.type) {
    case "REST_PASSWORD_START":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isRestPasswordFetching: true,
        },
        error: false,
      };
    case "REST_PASSWORD_SUCCESS":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isRestPasswordFetching: false,
        },
        error: false,
      };
    case "RESET_PASSWORD_FAILURE":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isRestPasswordFetching: false,
        },
        error: true,
      };
    case "COMMON_REST_PASSWORD_START":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isCommonRestPasswordFetching: true,
        },
        error: false,
      };
    case "COMMON_REST_PASSWORD_SUCCESS":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isCommonRestPasswordFetching: false,
        },
        error: false,
      };
    case "COMMON_RESET_PASSWORD_FAILURE":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isCommonRestPasswordFetching: false,
        },
        error: true,
      };
    case "GET_USER_PROFILE_PICTURE_START":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isGetUserProfilePictureFetching: true,
        },
        error: false,
      };
    case "GET_USER_PROFILE_PICTURE_SUCCESS":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isGetUserProfilePictureFetching: false,
        },
        error: false,
      };
    case "GET_USER_PROFILE_PICTURE_FAILURE":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isGetUserProfilePictureFetching: false,
        },
        error: true,
      };
    case "ADMIN_REST_PASSWORD_START":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isAdminRestPasswordFetching: true,
        },
        error: false,
      };
    case "ADMIN_REST_PASSWORD_SUCCESS":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isAdminRestPasswordFetching: false,
        },
        error: false,
      };
    case "ADMIN_RESET_PASSWORD_FAILURE":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isAdminRestPasswordFetching: false,
        },
        error: true,
      };
    case "ADD_NEW_EMPLOYER_START":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isAddNewEmployerFetching: true,
        },
        error: false,
      };
    case "ADD_NEW_EMPLOYER_SUCCESS":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isAddNewEmployerFetching: false,
        },
        error: false,
      };
    case "ADD_NEW_EMPLOYER_FAILURE":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isAddNewEmployerFetching: false,
        },
        error: true,
      };
      case "GENERATE_EMPLOYER_REGISTRATION_COMPLETE_LINK_START":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isgenerateEmployerRegistrationCompleteLinkFetching: true,
        },
        error: false,
      };
    case "GENERATE_EMPLOYER_REGISTRATION_COMPLETE_LINK_SUCCESS":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isgenerateEmployerRegistrationCompleteLinkFetching: false,
        },
        error: false,
      };
    case "GENERATE_EMPLOYER_REGISTRATION_COMPLETE_LINK_FAILURE":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isgenerateEmployerRegistrationCompleteLinkFetching: false,
        },
        error: true,
      };

    default: {
      return { ...state };
    }
  }
};

export default IdentityServiceReducer;
