const COMPANY_INITIAL_FETCHING_VALUES = {
  isSendCompanyNameFetching: false,
  isGetCompanyByIdFetching: false,
  isDisableCompanyFetching: false,
  isCreateCompanyFetching: false,
  isUpdateCompanyFetching: false,
};

export const COMPANY_INITIAL_VALUES = {
  companies: [],
  companyByIdDetails: null,
  error: false,
  companyFetchingState: {
    ...COMPANY_INITIAL_FETCHING_VALUES,
  },
};
