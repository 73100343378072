import "./css/main.css";
import "./scss/main.scss";
import "antd/lib/date-picker/style/css";
import "react-quill/dist/quill.snow.css";
import "tippy.js/dist/tippy.css";

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { lazy, Suspense, useContext } from "react";
import { LoginProtectRoute } from "./helpers/LoginProtectRoute";
import { ProtectRoute } from "./helpers/ProtectRoute";
import { PractitionerRoute } from "./helpers/PractitionerRoute";
import { MainContext } from "./context/MainContext";
import GlobalLoading from "./components/GetZen/GlobalLoader";

//Lazy Loading Components
const Home = lazy(() => import("./views/Home/Home"));
const CustomLogin = lazy(() => import("./views/login"));
const ForgotPassword = lazy(() => import("./views/login/ForgotPassword"));
const Clients = lazy(() => import("./views/client/Clients"));
const AddClient = lazy(() => import("./views/client/add-client/AddClient"));
const Practitioners = lazy(() => import("./views/practitioners/Practitioners"));
const AddPractitioners = lazy(() =>
  import("./views/practitioners/AddPractitioners")
);
const Programmes = lazy(() => import("./views/programmes/Programmes"));
const AddNewProgrammes = lazy(() =>
  import("./views/programmes/AddNewProgrammes")
);
const Employee = lazy(() => import("./views/employee/Employee"));
const EmployeeView = lazy(() => import("./views/employee/EmployeeView"));
const Reporting = lazy(() => import("./views/reporting/Reporting"));
const Viewreport = lazy(() => import("./views/reporting/Viewreport"));
const PendingPayment = lazy(() => import("./views/reporting/PendingPayment"));
const PendingInvoice = lazy(() => import("./views/reporting/PendingInvoice"));
const PaidInvoice = lazy(() => import("./views/reporting/PaidInvoice"));
const ProgrammeInsight = lazy(() =>
  import("./views/reporting/ProgrammeInsight")
);
const PractitionerReport = lazy(() =>
  import("./views/reporting/PractitionerReport")
);
const BusinessReport = lazy(() => import("./views/reporting/BusinessReport"));
const UpComingProgrammeBookingReport = lazy(() =>
  import("./views/reporting/UpComingProgrammeBookingReport")
);
const PastProgrammeBookingsReport = lazy(() =>
  import("./views/reporting/PastProgrammeBookingsReport")
);

export default function App() {
  const { user, userData, isLoading } = useContext(MainContext);

  return (
    <Suspense fallback={<GlobalLoading />}>
      <Router>
        <div className="App">
          <Routes>
            <Route
              exact
              path="/login"
              element={
                <LoginProtectRoute user={user} roleType={userData}>
                  <CustomLogin />
                </LoginProtectRoute>
              }
            />
            <Route
              exact
              path="/password-rest"
              element={
                <LoginProtectRoute user={user} roleType={userData}>
                  <ForgotPassword />
                </LoginProtectRoute>
              }
            />
            <Route
              path="/"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <Home />
                </PractitionerRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <Home />
                </PractitionerRoute>
              }
            />
            <Route
              path="/clients"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <Clients />
                </PractitionerRoute>
              }
            />
            <Route
              path="/addnewclient"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <AddClient />
                </PractitionerRoute>
              }
            />
            <Route
              path="/editclient/:id"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <AddClient type="edit" />
                </PractitionerRoute>
              }
            />
            <Route
              path="/employee"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <Employee />
                </PractitionerRoute>
              }
            />

            <Route
              path="/employeeView"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <EmployeeView />
                </PractitionerRoute>
              }
            />
            <Route
              path="/practitioners"
              element={
                <ProtectRoute user={user} roleType={userData}>
                  <Practitioners />
                </ProtectRoute>
              }
            />
            <Route
              path="/addpractitioner"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <AddPractitioners type="addNew" />
                </PractitionerRoute>
              }
            />
            <Route
              path="/practitioners/:id"
              element={
                <ProtectRoute user={user} roleType={userData}>
                  <AddPractitioners type="edit" />
                </ProtectRoute>
              }
            />
            <Route
              path="/programmes"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <Programmes />
                </PractitionerRoute>
              }
            />
            <Route
              path="/programmes/:id"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <AddNewProgrammes type="edit" />
                </PractitionerRoute>
              }
            />
            <Route
              path="/addNewProgrammes"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <AddNewProgrammes type="addNew" />
                </PractitionerRoute>
              }
            />
            <Route
              path="/reporting"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <Reporting />
                </PractitionerRoute>
              }
            />
            <Route
              path="/viewReport"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <Viewreport />
                </PractitionerRoute>
              }
            />
            <Route
              path="/pending-payment"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <PendingPayment />
                </PractitionerRoute>
              }
            />
            <Route
              path="/pending-invoice"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <PendingInvoice />
                </PractitionerRoute>
              }
            />
            <Route
              path="/paid-invoice"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <PaidInvoice />
                </PractitionerRoute>
              }
            />
            <Route
              path="/programme-insight"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <ProgrammeInsight />
                </PractitionerRoute>
              }
            />
            <Route
              path="/practitioner-report"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <PractitionerReport />
                </PractitionerRoute>
              }
            />
            <Route
              path="/business-report"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <BusinessReport />
                </PractitionerRoute>
              }
            />
            <Route
              path="/upcoming-programmes-bookings-report"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <UpComingProgrammeBookingReport />
                </PractitionerRoute>
              }
            />
            <Route
              path="/past-programmes-bookings-report"
              element={
                <PractitionerRoute user={user} roleType={userData}>
                  <PastProgrammeBookingsReport />
                </PractitionerRoute>
              }
            />
            <Route
              path="*"
              element={
                userData !== "PRAC" ? (
                  <Navigate to="/" />
                ) : (
                  <Navigate to="/practitioners" />
                )
              }
            />
          </Routes>
          {isLoading ? <GlobalLoading /> : null}
        </div>
      </Router>
    </Suspense>
  );
}
